import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let root = this.element;

    (function myLoop(i) {
      setTimeout(function() {
        if(root.querySelectorAll('iframe').length == 2) {
          root.querySelector('div.col').style.visibility = 'visible';
        }

        if (--i) myLoop(i);
      }, 1000)
    })(10);
  }
}
