import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import NextButton from '../NextButton';

export default class DatepickerStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    moment.locale(this.props.programDefaults.i18n.locale);

    this.dpref = React.createRef();
  }

  componentDidMount = () => {
    $(this.dpref.current).DatePicker({
      flat: true,
      date: '',
      prev: '<i class="fa fa-caret-left" aria-hidden="true"></i>',
      next: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
      calendars: 1,
      mode: 'range',
      onChange: (formated, dates) => {
        var date =
          formated[0] == this.props.bookingDetails.startDate
            ? formated[1]
            : formated[0]; // Work around the datepicker quirk.
        var startDate = moment(date);
        var endDate = this.props
          .currentVariant()
          .availability[startDate.format('YYYYMMDD')].slice(-1)[0];
        var startDateFormatted = startDate.format('YYYYMMDD');
        var endDateFormatted = moment(endDate).format('YYYYMMDD');

        this.props.actions.updateBookingDetails({
          bookingDetails: {
            startDate: startDateFormatted,
            endDate: endDateFormatted,
          },
        });
      },
      onRender: date => {
        var availability = this.props.currentVariant().availability[
          moment(date).format('YYYYMMDD')
        ];

        return {
          disabled:
            availability === undefined ||
            moment(date).isBefore(moment().startOf('day')) ||
            (availability !== undefined && availability < 1),
        };
      },
      starts: 1,
    });

    this.setDatepickerDate();
  };

  componentDidUpdate(prevProps, prevState) {
    this.setDatepickerDate();
  }

  get startDateMoment() {
    return moment(this.props.bookingDetails.startDate);
  }

  get endDateMoment() {
    return moment(this.props.bookingDetails.endDate);
  }

  get startDateText() {
    if (this.startDateMomentValid) {
      return this.startDateMoment.format(
        this.props.programDefaults.i18n.format,
      );
    } else {
      return this.props.intl.formatMessage({
        id: 'booking_wizard.sections.datepicker.no_dates_available',
      });
    }
  }

  get endDateText() {
    if (this.endDateMomentValid) {
      return this.endDateMoment.format(this.props.programDefaults.i18n.format);
    } else {
      return this.props.intl.formatMessage({
        id: 'booking_wizard.sections.datepicker.no_dates_available',
      });
    }
  }

  get valid() {
    return this.startDateMomentValid && this.endDateMomentValid;
  }

  get startDateMomentValid() {
    return (
      this.props.bookingDetails.startDate && this.startDateMoment.isValid()
    );
  }

  get endDateMomentValid() {
    return this.props.bookingDetails.endDate && this.endDateMoment.isValid();
  }

  setDatepickerDate = () => {
    if (this.props.bookingDetails.startDate) {
      var dates = [];
      var start = this.props.bookingDetails.startDate;
      var end = this.props.bookingDetails.endDate;

      this.props.currentVariant().availability[start].forEach(date => {
        dates.push(moment(date).toDate());
      });

      $(this.dpref.current).DatePickerSetDate(dates, true);
    } else {
      $(this.dpref.current).DatePickerSetDate('', false);
    }
  };

  onNext = () => {
    if (!this.props.reservationHandle) {
      this.props.actions.reserve({
        startDate: this.props.bookingDetails.startDate,
        endDate: this.props.bookingDetails.endDate,
      });
    }
    this.props.onNext();
  };

  freeDeliveryElement = () => {
    if (!this.props.bookingDetails.bill.handlingItem) {
      return (
        <p className="date-info__text--light date-info__text--free-delivery">
          {this.props.intl.formatMessage({
            id: 'booking_wizard.sections.datepicker.free_delivery',
          })}
        </p>
      );
    }
  };

  deliveryAndReturnElement = () => {
    if (
      this.props.bookingDetails.bill.handlingItem &&
      !this.props.bookingDetails.bill.demoItem
    ) {
      return (
        <div>
          <p className="date-info__text--light">
            {this.props.intl.formatMessage(
              { id: 'booking_wizard.sections.datepicker.no_fee' },
              {
                mode:
                  this.props.programDefaults.mode.charAt(0).toUpperCase() +
                  this.props.programDefaults.mode.slice(1),
              },
            )}
          </p>
          <p className="date-info__text--bold">
            {this.props.intl.formatMessage(
              { id: 'booking_wizard.sections.datepicker.delivery' },
              {
                amount: this.props.bookingDetails.bill.handlingItem
                  .amountFormatted,
              },
            )}
          </p>
        </div>
      );
    } else if (
      this.props.bookingDetails.bill.handlingItem &&
      this.props.bookingDetails.bill.demoItem
    ) {
      return (
        <p className="date-info__text--light">
          {this.props.intl.formatMessage(
            { id: 'booking_wizard.sections.datepicker.delivery' },
            {
              amount: this.props.bookingDetails.bill.handlingItem
                .amountFormatted,
            },
          )}
        </p>
      );
    } else if (
      !this.props.bookingDetails.bill.handlingItem &&
      !this.props.bookingDetails.bill.demoItem
    ) {
      return (
        <div>
          <p className="date-info__text--light">
            {this.props.intl.formatMessage(
              { id: 'booking_wizard.sections.datepicker.no_fee' },
              {
                mode:
                  this.props.programDefaults.mode.charAt(0).toUpperCase() +
                  this.props.programDefaults.mode.slice(1),
              },
            )}
          </p>
          <p className="date-info__text--bold">
            {this.props.intl.formatMessage({
              id: 'booking_wizard.sections.datepicker.free_delivery',
            })}
          </p>
        </div>
      );
    } else {
      return (
        <p className="date-info__text--light">
          {this.props.intl.formatMessage({
            id: 'booking_wizard.sections.datepicker.free_delivery',
          })}
        </p>
      );
    }
  };

  feeElement = () => {
    if (this.props.bookingDetails.bill.demoItem) {
      return <p className="date-info__text--bold">{this.feeText()}</p>;
    }
  };

  feeText = () => {
    if (this.props.bookingDetails.bill.demoItem) {
      return this.props.intl.formatMessage(
        { id: 'booking_wizard.sections.datepicker.fee' },
        {
          mode:
            this.props.programDefaults.mode.charAt(0).toUpperCase() +
            this.props.programDefaults.mode.slice(1),
          amount: this.props.bookingDetails.bill.demoItem.amountFormatted,
        },
      );
    } else {
      return this.props.intl.formatMessage(
        { id: 'booking_wizard.sections.datepicker.no_fee' },
        {
          mode:
            this.props.programDefaults.mode.charAt(0).toUpperCase() +
            this.props.programDefaults.mode.slice(1),
        },
      );
    }
  };

  periodSwitchElement = () => {
    if (this.props.variants.length > 1) {
      return (
        <div className="datepicker-periods">
          {this.props.variants.map((variant, index) => {
            return (
              <button
                className={`btn datepicker-periods-btn ${
                  this.props.currentVariantIndex === index
                    ? 'btn-primary'
                    : 'datepicker-periods-btn--inactive'
                }`}
                onClick={() => this.handlePeriodChange(index)}
                key={index}
              >
                <div className="datepicker-periods-btn--body">
                  <span>{variant.displayPeriodFormatted}</span>
                  <strong>
                    {variant.bookingDetails.bill.subtotalFormatted}
                  </strong>
                </div>
              </button>
            );
          })}
        </div>
      );
    }
  };

  handlePeriodChange = index => {
    this.props.actions.switchVariant(index);
  };

  render() {
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        <div className="container-fluid container__content container__content--datepicker">
          <div className="row flex-md-nowrap mx-md-0">
            <div className="col-md datepicker__col">
              {this.periodSwitchElement()}
              <div className="datepicker-container" ref={this.dpref}></div>
            </div>
            <div className="col-md period-info__col">
              <div className="content__date-info">
                <p className="content__info">
                  <FormattedMessage
                    id="booking_wizard.sections.datepicker.select"
                    values={{
                      count: this.props.programDefaults.period,
                      mode: this.props.programDefaults.mode,
                    }}
                  />
                </p>
                <div className="date-info__cell">
                  <p className="date-info__text--light">
                    {this.props.programDefaults.deliveryTimeText}
                  </p>
                  <p className="date-info__text--bold">{this.startDateText}</p>
                </div>
                <div className="date-info__cell">
                  <p className="date-info__text--light">
                    {this.props.programDefaults.returnTimeText}
                  </p>
                  <p className="date-info__text--bold">{this.endDateText}</p>
                </div>
                <div className="date-info__cell date-info__cost date-info__cost--detailed">
                  {this.deliveryAndReturnElement()}
                  {this.feeElement()}
                </div>
                <div className="date-info__cell date-info__cost date-info__cost--total">
                  <p className="date-info__text--light">
                    <FormattedMessage id="booking_wizard.sections.datepicker.total_cost" />
                  </p>
                  <p className="date-info__text--bold">
                    {this.props.bookingDetails.bill.subtotalFormatted}
                  </p>
                </div>
                <div className="date-info__cell date-info__cost date-info__cost--free">
                  {this.freeDeliveryElement()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container__buttons">
          <div className="row row__buttons">
            <div className="col">
              <button
                className="btn btn-default--modal-light"
                type="button"
                onClick={this.props.onCancel}
              >
                <FormattedMessage id="booking_wizard.navigation.cancel" />
              </button>
            </div>
            <div className="col">
              <NextButton
                stepName="datepicker"
                {...this.props}
                onNext={this.onNext}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// FIXME: better propTypes
DatepickerStep.propTypes = {
  programDefaults: PropTypes.object,
  bookingDetails: PropTypes.object,
  reservationHandle: PropTypes.string,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  intl: PropTypes.object,
  actions: PropTypes.object,
  validationStatus: PropTypes.object,
  variants: PropTypes.array,
  currentVariant: PropTypes.func,
  currentVariantIndex: PropTypes.number,
};
