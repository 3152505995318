export default class Ship2ShopLocation {
  constructor(loc) {
    this.loc = loc;
  }

  get latlon() {
    return new google.maps.LatLng(this.loc.coordinates.lat, this.loc.coordinates.lon);
  }

  get address() {
    return this.loc.address;
  }

  get addressCode() {
    return this.loc.addressCode;
  }
  
  get availability() {
    return this.loc.availability;
  }

  get availabilityExceptions() {
    return this.loc.availabilityExceptions;
  }

  toMapMarker = (map, onClick) => {
    var infoWindow = new google.maps.InfoWindow({
      content: this.pickupDescription()
    });

    var marker = new google.maps.Marker({
      position: this.latlon,
      map: map,
      title: this.address.name
    });

    marker.setValues({
      ship2shopLocation: this,
    });

    marker.addListener('click', () => onClick(infoWindow));

    return marker;
  }

  pickupDescription = () => {
    return '<h1>'+this.loc.address.name+'</h1>'+
      '<div>'+this.loc.address.street+
      '<div>'+this.loc.address.city+'</div>'+
      '<div>'+this.loc.address.postcode +'</div>';
  }

  renderHours = (hours) => {
    var period = '';
    if ( hours.length > 1 ) {
      period += (hours[0].startHour < 10 ? '0' : '' ) + hours[0].startHour + ':' + (hours[0].startMinute < 10 ? '0' : '' ) + hours[0].startMinute;
      period += '&nbsp;-&nbsp;' + (hours[0].endHour < 10 ? '0' : '' ) + hours[0].endHour + ':' + (hours[0].endMinute < 10 ? '0' : '' ) + hours[0].endMinute;
      period += ', '
        period += (hours[1].startHour < 10 ? '0' : '' ) + hours[1].startHour + ':' + (hours[1].startMinute < 10 ? '0' : '' ) + hours[1].startMinute;
      period += '&nbsp;-&nbsp;' + (hours[1].endHour < 10 ? '0' : '' ) + hours[1].endHour + ':' + (hours[1].endMinute < 10 ? '0' : '' ) + hours[1].endMinute;
    } else {
      period += (hours[0].startHour < 10 ? '0' : '' ) + hours[0].startHour + ':' + (hours[0].startMinute < 10 ? '0' : '' ) + hours[0].startMinute;
      period += ' - ' + (hours[0].endHour < 10 ? '0' : '' ) + hours[0].endHour + ':' + (hours[0].endMinute < 10 ? '0' : '' ) + hours[0].endMinute;
    }
    return period
  }

  renderAvailability = () => {
    var html = '';
    var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    for(var i=0; i<days.length; ++i) {
      var row_html = '';
      row_html = '<tr><td class="weekday">' + days[i] + '</td>';
      if (this.availability[i] == undefined) {
        row_html += '<td> Closed </td>'
      } else {
        row_html += '<td>' + this.renderHours(this.availability[i].hours) +'</td></tr>';
      }
      html += row_html;
    }
    return html;
  }
}
