import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

export default class NextButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  get valid() {
    if (this.props.onValidate) {
      return true;
    } else {
      return (
        this.props.validationStatus &&
        this.props.validationStatus[this.props.stepName]
      );
    }
  }

  click = e => {
    if (this.props.onValidate) {
      this.props.onValidate(e);
    } else {
      this.props.onNext(e);
    }
  };

  render() {
    return (
      <button
        className={classnames('btn btn-primary btn-default--modal', {
          'btn-default--modal-error': this.props.error,
        })}
        type="button"
        onClick={this.click}
        disabled={this.props.disabled || !this.valid}
      >
        {this.props.content || (
          <FormattedMessage id={`booking_wizard.navigation.continue`} />
        )}
      </button>
    );
  }
}

NextButton.propTypes = {
  stepName: PropTypes.string,
  onNext: PropTypes.func,
  onValidate: PropTypes.func,
  validationStatus: PropTypes.object,
};
