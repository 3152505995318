import PropTypes from 'prop-types';
import React from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  FormattedDate,
} from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import NextButton from '../NextButton';
import CardForm from '../CardForm';

import {
  StripeProvider,
  Elements,
  CardExpiryElement,
  CardNumberElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';

export default class PaymentStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      errorMsg: '',
      value: null,
      stripe: null,
    };

    this.cardRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentStepIndex != this.props.currentStepIndex) {
      this.setValue(this.bill.totalFormatted);
    }
  }

  componentDidMount() {
    this.props.actions.setValidationStatus('payment', true);
    if (window.Stripe) {
      this.setStripe(
        window.Stripe(this.props.stripePublishableKey),
      );
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setStripe(
          window.Stripe(this.props.stripePublishableKey),
        );
      });
    }
  }

  get bill() {
    return this.props.bookingDetails.bill;
  }

  setLoading = state => {
    this.setState({ loading: state });
  };

  setValue = value => {
    this.setState({ value: value });
  };

  setError = msg => {
    this.setState({ error: msg });
  };

  setErrorMsg = msg => {
    this.setState({ errorMsg: msg });
  };

  setStripe = apiKey => {
    this.setState({ stripe: apiKey });
  };

  triggerPayment = () => {
    this.setError(null);
    this.setErrorMsg(null);
    this.setLoading(true);
    this.cardRef.current.getWrappedInstance().handleSubmit();
  };

  paymentResult = res => {
    if (res.status === 'succeeded') {
      this.props.onSuccess();
    } else if (res.error === 'booking') {
      this.setError(res.error);
    } else if (res.error === 'payment') {
      this.setError(res.error);
      this.setErrorMsg(res.details);
    } else if (res.error === 'session') {
      alert('Session expired!');
      location.reload();
    }
    this.setLoading(false);
  };

  paymentButtonText = () => {
    var result = '';
    if (document.body.dataset.preview) {
      result = 'booking disabled in preview';
    } else if (this.state.error === 'payment') {
      result = (
        <FormattedHTMLMessage
          id={`booking_wizard.sections.payment.errors.payment`}
        />
      );
    } else if (this.state.error === 'booking') {
      result = (
        <FormattedHTMLMessage
          id={`booking_wizard.sections.payment.errors.booking`}
        />
      );
    } else {
      result = (
        <FormattedHTMLMessage
          id={`booking_wizard.sections.payment.btn_confirm`}
          values={{ price: this.state.value }}
        />
      );
    }

    return result;
  };

  termsLink = () => {
    if (this.props.programDefaults.termsUrl) {
      return (
        <a
          className="btn btn-link btn-terms-link"
          target="_blank"
          rel="noopener noreferrer"
          href={this.props.programDefaults.termsUrl}
        >
          <FormattedMessage id={'booking_wizard.sections.payment.tandc'} />
        </a>
      );
    } else {
      return (
        <button
          className="btn btn-link btn-terms-link"
          type="button"
          onClick={this.props.onToggleTerms}
        >
          <FormattedMessage id={'booking_wizard.sections.payment.tandc'} />
        </button>
      );
    }
  };

  errorMsgWindow = () => {
    if (this.state.errorMsg) {
      return (
        <div className="container__payment-form-wrapper mb-0">
          <label className="StripeElement CardElement__label CardElement__label--error">
            <i className="fas fa-exclamation-circle" />
            <span>{this.state.errorMsg}</span>
          </label>
        </div>
      );
    }
  };

  render() {
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        <div className="container-fluid container__content container__content--payment">
          <div className="container__payment-form-wrapper">
            <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <CardForm
                  ref={this.cardRef}
                  paymentResult={this.paymentResult}
                  {...this.props}
                />
              </Elements>
            </StripeProvider>
          </div>
          {this.errorMsgWindow()}
          <div className="terms__header">
            <FormattedHTMLMessage
              id={'booking_wizard.sections.payment.terms_header'}
            />
          </div>
          <div className="payment__terms">
            <div>
              <FormattedMessage
                id={'booking_wizard.sections.payment.terms'}
                values={{
                  link: this.termsLink(),
                  mode: this.props.programDefaults.mode,
                }}
              />
            </div>
          </div>
        </div>
        <div className="container__buttons container__buttons--summary-step">
          <div className="row row__buttons">
            <div className="col">
              <button
                className="btn btn-default--modal-light"
                type="button"
                onClick={this.props.onPrev}
              >
                <FormattedMessage id={`booking_wizard.navigation.back`} />
              </button>
            </div>
            <div className="col">
              <NextButton
                onNext={this.triggerPayment}
                error={this.state.error}
                stepName="payment"
                content={
                  <span>
                    <i
                      className="fas fa-spinner fa-pulse fa-fw"
                      style={{
                        display: this.state.loading ? 'inline-block' : 'none',
                      }}
                    ></i>
                    &nbsp;
                    <span>
                      <i className="fal fa-credit-card"></i>
                      &nbsp;
                      {this.paymentButtonText()}
                    </span>
                  </span>
                }
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
