import { Controller } from "stimulus"
import Cookie from "js-cookie"

export default class extends Controller {
  static targets = ["notificationButton"];

  acceptCookies(e) {
    Cookie.set('cookies_notification_check', true, { expires: 365 });
    $("#cookies-notification").fadeOut(200);
  }
}
