import { hot } from 'react-hot-loader'
import React from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';

import EnvioContainer from '../containers/EnvioContainer';

const EnvioApp = (props, _railsContext) => (
  <Provider store={ReactOnRails.getStore('store')}>
    <EnvioContainer />
  </Provider>
);

export default module.hot ? hot(module)(EnvioApp) : EnvioApp;
