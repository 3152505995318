import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { defaultMessages } from '../translations/default';

import FitText from '@kennethormandy/react-fittext';

export default class HelpCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  generateFaq = () => {
    return this.props.content.items.map((i, key) => (
      <div key={key} className="container-fluid container__content-qa">
        <div className="content-qa__question">{i.question}</div>
        <div className="content-qa__answer">{i.answer}</div>
      </div>
    ));
  };

  render() {
    let text = this.props.brand + ' Support';
    let compressor = text.length / 20;

    return (
      <div style={this.props.show ? { display:  'block', height: '100%' } : { display: 'none' }}>
        <div className="content__close-btn" onClick={this.props.onClose}></div>
        <div className="content__header-image--help content__header-image">
          <span className="header-image__text">
            <FitText compressor={compressor} maxFontSize={35}>
              {text}
            </FitText>
          </span>
        </div>
        <div className="container-fluid container__content--faq">
          {this.generateFaq()}
        </div>
        <div className="container__buttons">
          <div className="row row__buttons">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary content__btn-help--back"
                onClick={this.props.toggleHelp}
              >
                <FormattedMessage
                  id={
                    this.props.initialOpen
                      ? 'booking_wizard.help.start'
                      : 'booking_wizard.help.back'
                  }
                />
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid container__footer">
          <div className="row footer__row footer__row--powered">
            <div className="col">
              &nbsp;
              <span>
                <FormattedMessage id="booking_wizard.footer.powered" />
              </span>
              &nbsp;
              <span>
                <a
                  href="http://envio.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="booking_wizard.footer.company_link" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
