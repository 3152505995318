/* eslint-disable import/prefer-default-export */

export const ENVIO_SEED_DEFAULTS = 'ENVIO_SEED_DEFAULTS';
export const ENVIO_TEST_AVAILABILITY = 'ENVIO_TEST_AVAILABILITY';
export const ENVIO_UPDATE_CUSTOMER_DETAILS = 'ENVIO_UPDATE_CUSTOMER_DETAILS';
export const ENVIO_SET_VALIDATION_STATUS = 'ENVIO_SET_VALIDATION_STATUS';
export const ENVIO_PICKUP_DETAILS = 'ENVIO_PICKUP_DETAILS';
export const ENVIO_PICKUP_MODE = 'ENVIO_PICKUP_MODE';
export const ENVIO_BOOKING_DETAILS = 'ENVIO_BOOKING_DETAILS';
export const ENVIO_GEOCODE_ADDRESS = 'ENVIO_GEOCODE_ADDRESS';
export const ENVIO_SHIP2SHOP_LOCATIONS = 'ENVIO_SHIP2SHOP_LOCATIONS';
export const ENVIO_BOOKING_WIZARD_LAUNCH = 'ENVIO_BOOKING_WIZARD_LAUNCH';
export const ENVIO_BOOKING_WIZARD_CLOSE = 'ENVIO_BOOKING_WIZARD_CLOSE';
export const ENVIO_TRY_VOUCHER_CODE = 'ENVIO_TRY_VOUCHER_CODE';
export const ENVIO_BOOK = 'ENVIO_BOOK';
export const ENVIO_PAY = 'ENVIO_PAY';
export const ENVIO_RESERVE = 'ENVIO_RESERVE';
export const ENVIO_UNRESERVE = 'ENVIO_UNRESERVE';
export const ENVIO_SET_RESERVATION_HANDLE = 'ENVIO_SET_RESERVATION_HANDLE';
export const ENVIO_SWITCH_VARIANT = 'ENVIO_SWITCH_VARIANT';
