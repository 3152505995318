import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

export default class Steps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  get count() {
    return React.Children.toArray(this.props.children).length;
  }

  render() {
    const currentIndex = this.props.currentStepIndex;

    if (this.props.useTransition) {
      return (
        <div className="steps-transitions">
          {React.Children.toArray(this.props.children).map((child, index) => {
            return (
              <CSSTransition
                key={index}
                in={currentIndex == index}
                appear={currentIndex == index}
                timeout={500}
                classNames="step-transition-"
              >
                {child}
              </CSSTransition>
            );
          })}
        </div>
      );
    } else {
      return React.Children.toArray(this.props.children).map((child, index) => {
        return (
          <div
            key={index}
            style={
              currentIndex == index
                ? { display: 'block', height: '78%' }
                : { display: 'none' }
            }
          >
            {child}
          </div>
        );
      });
    }
  }
}

Steps.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
};

Steps.defaultProps = {
  currentStepIndex: 0,
};
