import Ship2ShopLocation from '../libs/Ship2ShopLocation';

import { combineReducers } from 'redux';
import { ENVIO_SEED_DEFAULTS } from '../constants/envioConstants';
import { ENVIO_UPDATE_CUSTOMER_DETAILS } from '../constants/envioConstants';
import { ENVIO_SET_VALIDATION_STATUS } from '../constants/envioConstants';
import { ENVIO_PICKUP_DETAILS } from '../constants/envioConstants';
import { ENVIO_PICKUP_MODE } from '../constants/envioConstants';
import { ENVIO_BOOKING_DETAILS } from '../constants/envioConstants';
import { ENVIO_GEOCODE_ADDRESS } from '../constants/envioConstants';
import { ENVIO_SHIP2SHOP_LOCATIONS } from '../constants/envioConstants';
import { ENVIO_BOOKING_WIZARD_LAUNCH } from '../constants/envioConstants';
import { ENVIO_BOOKING_WIZARD_CLOSE } from '../constants/envioConstants';
import { ENVIO_SET_RESERVATION_HANDLE } from '../constants/envioConstants';
import { ENVIO_SWITCH_VARIANT } from '../constants/envioConstants';

const envioReducer = (state = {}, action) => {
  state.lastAction = action;

  switch (action.type) {
    case ENVIO_SEED_DEFAULTS:
      return processSeedDefaults(state, action);
    case ENVIO_UPDATE_CUSTOMER_DETAILS:
      return processCustomerDetails(state, action);
    case ENVIO_SET_VALIDATION_STATUS:
      return processValidationStatus(state, action);
    case ENVIO_PICKUP_DETAILS:
      return processPickupDetails(state, action);
    case ENVIO_PICKUP_MODE:
      return processPickupMode(state, action);
    case ENVIO_BOOKING_DETAILS:
      return processBookingDetails(state, action);
    case ENVIO_GEOCODE_ADDRESS:
      return processGeocodeAddress(state, action);
    case ENVIO_SHIP2SHOP_LOCATIONS:
      return processShip2shopLocations(state, action);
    case ENVIO_BOOKING_WIZARD_LAUNCH:
      return bookingWizardLaunch(state, action);
    case ENVIO_BOOKING_WIZARD_CLOSE:
      return bookingWizardClose(state, action);
    case ENVIO_SET_RESERVATION_HANDLE:
      return programSetReservationHandle(state, action);
    case ENVIO_SWITCH_VARIANT:
      return processSwitchVariant(state, action);
    default:
      return state;
  }
};

const processSeedDefaults = (state = {}, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const processCustomerDetails = (state = {}, action) => {
  return {
    ...state,
    customerDetails: {
      firstName: action.payload.customerDetails.firstName,
      lastName: action.payload.customerDetails.lastName,
      email: action.payload.customerDetails.email,
      mobile: action.payload.customerDetails.mobile,
      birthdate: action.payload.customerDetails.birthdate,
      billingAddress: action.payload.customerDetails.billingAddress,
      billingAddress2: action.payload.customerDetails.billingAddress2,
      city: action.payload.customerDetails.city,
      postcode: action.payload.customerDetails.postcode,
      county: action.payload.customerDetails.county,
    },
  };
};

const processValidationStatus = (state = {}, action) => {
  var val = {};
  val[action.section] = action.status;

  return {
    ...state,
    validationStatus: {
      ...state.validationStatus,
      ...val,
    },
  };
};

const processPickupDetails = (state = {}, action) => {
  return {
    ...state,
    pickupDetails: {
      organization: action.payload.pickupDetails.organization,
      countryCode: action.payload.pickupDetails.countryCode,
      property: action.payload.pickupDetails.property,
      postcode: action.payload.pickupDetails.postcode,
      street: action.payload.pickupDetails.street,
      locality: action.payload.pickupDetails.locality,
      town: action.payload.pickupDetails.town,
      county: action.payload.pickupDetails.county,
      code: action.payload.pickupDetails.code,
      details: action.payload.pickupDetails.details,
      shop: action.payload.pickupDetails.shop,
    },
  };
};

const processPickupMode = (state = {}, action) => {
  return {
    ...state,
    pickupMode: {
      ship2shop: action.payload.ship2shop,
      valid: action.payload.valid,
    },
  };
};

const processBookingDetails = (state = {}, action) => {
  var newState = { ...state };
  newState.bookingDetails = { ...state.bookingDetails };

  if (action.payload.bookingDetails.id !== undefined) {
    newState.bookingDetails.id = action.payload.bookingDetails.id;
  }

  if (
    action.payload.bookingDetails.startDate &&
    action.payload.bookingDetails.endDate
  ) {
    newState.bookingDetails.startDate = action.payload.bookingDetails.startDate;
    newState.bookingDetails.endDate = action.payload.bookingDetails.endDate;
  }

  if (action.payload.bookingDetails.voucherCode != undefined) {
    newState.bookingDetails.voucherCode =
      action.payload.bookingDetails.voucherCode;
  }

  if (action.payload.bookingDetails.created) {
    newState.bookingDetails.created = action.payload.bookingDetails.created;
  }

  if (action.payload.bookingDetails.bill) {
    newState.bookingDetails.bill = action.payload.bookingDetails.bill;
  }

  return newState;
};

const processGeocodeAddress = (state = {}, action) => {
  var newState = { ...state };
  newState.customerDetails = { ...state.customerDetails };

  newState.customerDetails.latitude = action.payload.latitude;
  newState.customerDetails.longitude = action.payload.longitude;

  return newState;
};

const processShip2shopLocations = (state = {}, action) => {
  var newState = { ...state };
  newState.ship2shopLocations = action.payload.map(shop => {
    return new Ship2ShopLocation(shop);
  });

  return newState;
};

const bookingWizardLaunch = (state = {}, action) => {
  var newState = { ...state };
  newState.bookingWizardModal = { visible: true, showHelp: action.showHelp };
  if (window.parent) {
    window.parent.postMessage('envioBookingModalOpened', '*');
  }

  return newState;
};

const bookingWizardClose = (state = {}, action) => {
  var newState = { ...state };
  newState.bookingWizardModal = { visible: false };
  if (window.parent) {
    window.parent.postMessage('envioBookingModalClosed', '*');
  }
  return newState;
};

const programSetReservationHandle = (state = {}, action) => {
  var newState = { ...state };

  newState.reservationHandle = action.handle;

  return newState;
};

const processSwitchVariant = (state = {}, action) => {
  var newState = { ...state };
  var newBookingDetails = state.variants[action.payload].bookingDetails;

  newState.currentVariantIndex = action.payload;

  newState.validationStatus.datepicker =
    state.variants[action.payload].validation;

  newState.bookingDetails = { ...newBookingDetails };

  newState.bookingDetails.startDate = newBookingDetails.startDate;
  newState.bookingDetails.endDate = newBookingDetails.endDate;
  newState.bookingDetails.bill = newBookingDetails.bill;

  return newState;
};

//const envioReducer = combineReducers({
//programDefaults: programDefaultsReducer,
//customerDetails: customerDetailsReducer,
//});

export default envioReducer;
