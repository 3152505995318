import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import BookingWizard from './BookingWizard';
import Axios from 'axios';

// Intl
import { IntlProvider, injectIntl } from 'react-intl';
import { translations } from '../translations/translations';
import { defaultLocale } from '../translations/default';

const locale = defaultLocale;
const messages = translations[locale];

var BookingWizardWithIntl = injectIntl(BookingWizard);

export default class BookingWizardModal extends React.Component {
  constructor(props) {
    super(props);

    if (!props.programDefaults) {
      this.props.actions.fetchDefaults();
    }

    this.state = {};
  }

  close = () => {
    this.props.actions.closeBookingWizardModal();
  };

  get visible() {
    return (
      (this.props.bookingWizardModal &&
        this.props.bookingWizardModal.visible) ||
      false
    );
  }

  afterOpenModal = () => {
    if(window.envioEventBeacon) {
      window.envioEventBeacon('modal_opened');
    }
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.visible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.close}
          contentLabel="Example Modal"
          closeTimeoutMS={500}
          portalClassName="bwm__portal envio-base"
          overlayClassName={{
            base: 'bwm__overlay',
            afterOpen: 'bwm__overlay--after-open',
            beforeClose: 'bwm__overlay--before-close',
          }}
          className={{
            base: 'bwm__content',
            afterOpen: 'bwm__content--after-open',
            beforeClose: 'bwm__content--before-close',
          }}
          bodyOpenClassName="bwm__body--open"
          htmlOpenClassName="bwm__html--open"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >
          <IntlProvider locale={locale} key={locale} messages={messages}>
            <BookingWizardWithIntl onModalClose={this.close} {...this.props} />
          </IntlProvider>
        </Modal>
      </div>
    );
  }
}
