import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import NextButton from '../NextButton';

export default class CustomerDetailsStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        birthdate: {
          day: '',
          month: '',
          year: '',
        },
        billingAddress: '',
        billingAddress2: '',
        city: '',
        postcode: '',
        county: '',

        ...props.customerDetails,
      },
      validation: {
        form: null,
        age: null,
        firstName: null,
        lastName: null,
      },
      loading: false,
    };

    moment.locale(this.props.programDefaults.i18n.locale);

    this.formRef = React.createRef();
    this.birthdateDayRef = React.createRef();
    this.birthdateMonthRef = React.createRef();
    this.birthdateYearRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
  }

  setValue = newData => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        ...newData,
        birthdate: {
          ...prevState.data.birthdate,
          ...newData.birthdate,
        },
      },
    }));
  };

  validate = () => {
    var birthdateValid = moment(this.state.data.birthdate)
      .add(18, 'years')
      .isBefore(moment());

    if (!birthdateValid) {
      this.birthdateDayRef.current.setCustomValidity('under18');
      this.birthdateMonthRef.current.setCustomValidity('under18');
      this.birthdateYearRef.current.setCustomValidity('under18');
    } else {
      this.birthdateDayRef.current.setCustomValidity('');
      this.birthdateMonthRef.current.setCustomValidity('');
      this.birthdateYearRef.current.setCustomValidity('');
    }

    if (this.state.data.firstName.length < 2)
      this.firstNameRef.current.setCustomValidity('tooShort');
    else this.firstNameRef.current.setCustomValidity('');

    if (this.state.data.lastName.length < 2)
      this.lastNameRef.current.setCustomValidity('tooShort');
    else this.lastNameRef.current.setCustomValidity('');

    var formValid = this.formRef.current.checkValidity();
    var firstNameValid = this.firstNameRef.current.checkValidity();
    var lastNameValid = this.lastNameRef.current.checkValidity();
    this.setState({
      validation: {
        form: formValid,
        age: birthdateValid,
        firstName: firstNameValid,
        lastName: lastNameValid,
      },
    });

    this.props.actions.setValidationStatus('billing', formValid);
    this.props.actions.setValidationStatus('age', birthdateValid);

    if (formValid && birthdateValid) {
      this.props.actions
        .updateCustomerDetails({
          customerDetails: this.state.data,
          programDefaults: {
            shipmentProvider: this.props.programDefaults.shipmentProvider,
          },
        })
        .then(response => {
          this.setState({ validation: { address: response.valid } });

          if (response.valid) {
            this.props.onNext();
          }
        });
    }
  };

  validationMessages = () => {
    if (this.state.validation.age === false) {
      return (
        <p className="text-danger">
          <FormattedMessage
            id={'booking_wizard.sections.customer_details.validation_age'}
          />
        </p>
      );
    } else if (this.state.validation.firstName === false) {
      return (
        <p className="text-danger d-none d-md-block">
          <FormattedMessage
            id={'booking_wizard.sections.customer_details.short_first_name'}
          />
        </p>
      );
    } else if (this.state.validation.lastName === false) {
      return (
        <p className="text-danger d-none d-md-block">
          <FormattedMessage
            id={'booking_wizard.sections.customer_details.short_last_name'}
          />
        </p>
      );
    } else if (this.state.validation.form === false) {
      return (
        <p className="text-danger d-none d-md-block">
          <FormattedMessage
            id={'booking_wizard.sections.customer_details.validation_form'}
          />
        </p>
      );
    } else if (
      this.props.validationStatus &&
      this.props.validationStatus.address === false
    ) {
      return (
        <p className="text-danger">
          <FormattedMessage
            id={'booking_wizard.sections.customer_details.validation_address'}
          />
        </p>
      );
    }
  };

  onPrev = () => {
    if (this.props.reservationHandle) {
      this.props.actions.unreserve();
    }
    this.props.onPrev();
  };

  render() {
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        <div className="container-fluid container__content container__content--details-form">
          <form
            className={classnames('customer-details-form', {
              'was-validated': this.state.validation.form !== null,
            })}
            noValidate=""
            ref={this.formRef}
          >
            <div className="form-row">
              <div className="form-group col-6">
                <input
                  className="form-control"
                  value={this.state.data.firstName}
                  required={true}
                  ref={this.firstNameRef}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.first_name',
                  })}
                  onChange={e => this.setValue({ firstName: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={
                      'booking_wizard.sections.customer_details.invalid_first_name'
                    }
                  />
                </div>
              </div>
              <div className="form-group col-6">
                <input
                  className="form-control"
                  value={this.state.data.lastName}
                  required={true}
                  ref={this.lastNameRef}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.last_name',
                  })}
                  onChange={e => this.setValue({ lastName: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={
                      'booking_wizard.sections.customer_details.invalid_last_name'
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  type="email"
                  value={this.state.data.email}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.email',
                  })}
                  onChange={e => this.setValue({ email: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={
                      'booking_wizard.sections.customer_details.invalid_email_feedback'
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  value={this.state.data.mobile}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.mobile',
                  })}
                  onChange={e => this.setValue({ mobile: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={
                      'booking_wizard.sections.customer_details.invalid_mobile_feedback'
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3 col-sm-12">
                <p className="form__text">
                  <FormattedMessage
                    id={
                      'booking_wizard.sections.customer_details.date_of_birth'
                    }
                  />
                </p>
              </div>
              <div className="form-group col-md-3 col-4">
                <select
                  className="form-control form-control--birthdate"
                  ref={this.birthdateMonthRef}
                  value={this.state.data.birthdate.month}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.month',
                  })}
                  onChange={e =>
                    this.setValue({ birthdate: { month: e.target.value } })
                  }
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: 'booking_wizard.sections.customer_details.month',
                    })}
                  </option>
                  {[...Array(12)]
                    .map((v, i) => i)
                    .map(i => (
                      <option key={i} value={i}>
                        {this.props.intl.formatDate(new Date(1970, i, 1), {
                          month: 'long',
                        })}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-3 col-4">
                <select
                  className="form-control form-control--birthdate"
                  ref={this.birthdateDayRef}
                  value={this.state.data.birthdate.day}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.day',
                  })}
                  onChange={e =>
                    this.setValue({ birthdate: { day: e.target.value } })
                  }
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: 'booking_wizard.sections.customer_details.day',
                    })}
                  </option>
                  {[...Array(31)]
                    .map((v, i) => i)
                    .map(i => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-3 col-4">
                <select
                  className="form-control form-control--birthdate"
                  ref={this.birthdateYearRef}
                  value={this.state.data.birthdate.year}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.customer_details.year',
                  })}
                  onChange={e =>
                    this.setValue({ birthdate: { year: e.target.value } })
                  }
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: 'booking_wizard.sections.customer_details.year',
                    })}
                  </option>
                  {[...Array(81)]
                    .map((v, i) => new Date().getFullYear() - 18 - i)
                    .map(i => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 order-1">
                <input
                  className="form-control"
                  value={this.state.data.billingAddress}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id:
                      'booking_wizard.sections.customer_details.shipping_address',
                  })}
                  onChange={e =>
                    this.setValue({ billingAddress: e.target.value })
                  }
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={
                      'booking_wizard.sections.customer_details.invalid_shipping_address'
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-6 order-3 order-md-2">
                <input
                  className="form-control"
                  value={this.state.data.city}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: `booking_wizard.sections.customer_details.${this.props.programDefaults.countryCode.toLowerCase()}.town`,
                  })}
                  onChange={e => this.setValue({ city: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={`booking_wizard.sections.customer_details.${this.props.programDefaults.countryCode.toLowerCase()}.invalid_shipping_town`}
                  />
                </div>
              </div>
              <div className="form-group col-md-6 order-2 order-md-3">
                <input
                  className="form-control"
                  value={this.state.data.billingAddress2}
                  required={false}
                  placeholder={this.props.intl.formatMessage({
                    id:
                      'booking_wizard.sections.customer_details.shipping_address_optional',
                  })}
                  onChange={e =>
                    this.setValue({ billingAddress2: e.target.value })
                  }
                />
              </div>
              <div className="form-group col-md-3 order-4">
                <input
                  className="form-control"
                  value={this.state.data.postcode}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: `booking_wizard.sections.customer_details.${this.props.programDefaults.countryCode.toLowerCase()}.postcode`,
                  })}
                  onChange={e => this.setValue({ postcode: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={`booking_wizard.sections.customer_details.${this.props.programDefaults.countryCode.toLowerCase()}.invalid_shipping_postcode`}
                  />
                </div>
              </div>
              <div className="form-group col-md-3 order-5">
                <input
                  className="form-control"
                  value={this.state.data.county}
                  required={true}
                  placeholder={this.props.intl.formatMessage({
                    id: `booking_wizard.sections.customer_details.${this.props.programDefaults.countryCode.toLowerCase()}.county`,
                  })}
                  onChange={e => this.setValue({ county: e.target.value })}
                />
                <div className="invalid-feedback d-md-none">
                  <FormattedMessage
                    id={`booking_wizard.sections.customer_details.${this.props.programDefaults.countryCode.toLowerCase()}.invalid_shipping_county`}
                  />
                </div>
              </div>
            </div>
          </form>
          {this.validationMessages()}
        </div>
        <div className="container__buttons">
          <div className="row row__buttons">
            <div className="col">
              <button
                className="btn btn-default--modal-light"
                type="button"
                onClick={this.onPrev}
              >
                <FormattedMessage id={`booking_wizard.navigation.back`} />
              </button>
            </div>
            <div className="col">
              <NextButton
                loading={this.state.loading}
                onValidate={this.validate}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
