/* eslint-disable import/prefer-default-export */

import Axios from 'axios';
import humps from 'humps';

import { ENVIO_FETCH_DEFAULTS } from '../constants/envioConstants';
import { ENVIO_SEED_DEFAULTS } from '../constants/envioConstants';
import { ENVIO_TEST_AVAILABILITY } from '../constants/envioConstants';
import { ENVIO_UPDATE_CUSTOMER_DETAILS } from '../constants/envioConstants';
import { ENVIO_SET_VALIDATION_STATUS } from '../constants/envioConstants';
import { ENVIO_PICKUP_DETAILS } from '../constants/envioConstants';
import { ENVIO_PICKUP_MODE } from '../constants/envioConstants';
import { ENVIO_BOOKING_DETAILS } from '../constants/envioConstants';
import { ENVIO_SHIP2SHOP_LOCATIONS } from '../constants/envioConstants';
import { ENVIO_GEOCODE_ADDRESS } from '../constants/envioConstants';
import { ENVIO_BOOKING_WIZARD_LAUNCH } from '../constants/envioConstants';
import { ENVIO_BOOKING_WIZARD_CLOSE } from '../constants/envioConstants';
import { ENVIO_TRY_VOUCHER_CODE } from '../constants/envioConstants';
import { ENVIO_BOOK } from '../constants/envioConstants';
import { ENVIO_PAY } from '../constants/envioConstants';
import { ENVIO_RESERVE } from '../constants/envioConstants';
import { ENVIO_SET_RESERVATION_HANDLE } from '../constants/envioConstants';
import { ENVIO_SWITCH_VARIANT } from '../constants/envioConstants';

export const seedDefaults = payload => ({
  type: ENVIO_SEED_DEFAULTS,
  payload,
});

export const fetchDefaults = () => {
  return function (dispatch, getState) {
    return Axios({
      method: 'get',
      url: `${getState().apiBase || ''}/api/ui/v1/bookings/defaults`,
      responseType: 'json',
    }).then(response => {
      dispatch(seedDefaults(response.data));

      return response.data;
    });
  };
};

export const geocodeAddress = payload => {
  return function (dispatch, getState) {
    dispatch({
      type: ENVIO_GEOCODE_ADDRESS,
      payload,
    });

    return Axios({
      method: 'get',
      url: `${getState().apiBase || ''}/api/ui/v1/shipping/locations`,
      responseType: 'json',
      params: payload,
    }).then(response => {
      if (response.data.locations.length > 0) {
        dispatch({
          type: 'ENVIO_SHIP2SHOP_LOCATIONS',
          payload: response.data.locations,
        });
      } else {
        dispatch({ type: 'ENVIO_SHIP2SHOP_LOCATIONS', payload: [] });
        dispatch({
          type: 'ENVIO_PICKUP_MODE',
          payload: {
            ship2shop: false,
            valid: true,
          },
        });
        dispatch({
          type: 'ENVIO_SET_VALIDATION_STATUS',
          section: 'shipping',
          status: true,
        });
      }
      return response.data;
    });
  };
};

export const updateCustomerDetails = payload => {
  return function (dispatch, getState) {
    dispatch({
      type: ENVIO_UPDATE_CUSTOMER_DETAILS,
      payload,
    });

    var geocode = () => {
      if (payload.programDefaults.shipmentProvider == 'dpd') {
        var customerAddressStr;
        customerAddressStr = $.grep(
          [
            payload.customerDetails.billingAddress,
            payload.customerDetails.billingAddress2,
            payload.customerDetails.city +
            ' ' +
            payload.customerDetails.postcode,
          ],
          Boolean,
        ).join(', ');
        if (customerAddressStr) {
          var geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            {
              address: customerAddressStr,
              region: getState().programDefaults.countryCode,
            },
            function (results, status) {
              if (status === 'OK') {
                for (var i = 0, len = results.length; i < len; i++) {
                  var result = results[i];
                  dispatch(
                    geocodeAddress({
                      latitude: result.geometry.location.lat(),
                      longitude: result.geometry.location.lng(),
                    }),
                  );
                  return;
                }
              }
            },
          );
        }
      }
    };

    if (
      getState().programDefaults.countryCode == 'GB' &&
      getState().programDefaults.shipmentProvider == 'dpd'
    ) {
      var data = {
        countryCode: getState().programDefaults.countryCode,
        postcode: getState().customerDetails.postcode,
        locality: getState().customerDetails.billingAddress,
        county: getState().customerDetails.county,
      };

      return Axios({
        method: 'get',
        url: `${getState().apiBase || ''}/api/ui/v1/shipping/services`,
        responseType: 'json',
        params: data,
      }).then(response => {
        dispatch({
          type: 'ENVIO_PICKUP_MODE',
          payload: {
            ship2shop: response.data.ship2shop,
            valid: response.data.valid,
          },
        });

        if (response.data.valid) {
          dispatch({
            type: 'ENVIO_SET_VALIDATION_STATUS',
            section: 'address',
            status: true,
          });
          geocode();
          if (!response.data.ship2shop) {
            dispatch({
              type: 'ENVIO_SET_VALIDATION_STATUS',
              section: 'shipping',
              status: true,
            });
          }
        } else {
          dispatch({
            type: 'ENVIO_SET_VALIDATION_STATUS',
            section: 'address',
            status: false,
          });
        }

        return response.data;
      });
    } else {
      dispatch({
        type: 'ENVIO_PICKUP_MODE',
        payload: {
          ship2shop: false,
          valid: true,
        },
      });
      dispatch({
        type: 'ENVIO_SET_VALIDATION_STATUS',
        section: 'address',
        status: true,
      });
      dispatch({
        type: 'ENVIO_SET_VALIDATION_STATUS',
        section: 'shipping',
        status: true,
      });
      geocode();

      return new Promise((resolve, reject) => {
        resolve({ valid: true });
      });
    }
  };
};

export const setValidationStatus = (section, status) => ({
  type: ENVIO_SET_VALIDATION_STATUS,
  section,
  status,
});

export const pickupDetails = payload => ({
  type: ENVIO_PICKUP_DETAILS,
  payload,
});

export const pickupMode = payload => ({
  type: ENVIO_PICKUP_MODE,
  payload,
});

export const bookingDetails = payload => ({
  type: ENVIO_BOOKING_DETAILS,
  payload,
});

export const switchVariant = payload => ({
  type: ENVIO_SWITCH_VARIANT,
  payload,
});

export const ship2shopLocations = payload => ({
  type: ENVIO_SHIP2SHOP_LOCATIONS,
  payload,
});

export const bookingWizardLaunch = showHelp => ({
  type: ENVIO_BOOKING_WIZARD_LAUNCH,
  showHelp,
});

export const bookingWizardClose = () => ({
  type: ENVIO_BOOKING_WIZARD_CLOSE,
});

export const setReservationHandle = handle => ({
  type: ENVIO_SET_RESERVATION_HANDLE,
  handle,
});

export const tryVoucherCode = code => {
  return function (dispatch, getState) {
    return Axios({
      method: 'get',
      url: `${getState().apiBase || ''}/api/ui/v1/bookings/voucher`,
      responseType: 'json',
      params: {
        code,
        variantId: getState().currentVariant().id,
      },
    }).then(response => {
      dispatch(
        bookingDetails({
          bookingDetails: {
            voucherCode: code,
            bill: response.data,
          },
        }),
      );
      return response.data;
    });
  };
};

export const book = () => {
  return function (dispatch, getState) {
    var data = {
      variantId: getState().currentVariant().id,
      startDate: getState().bookingDetails.startDate,
      endDate: getState().bookingDetails.endDate,
      firstName: getState().customerDetails.firstName,
      lastName: getState().customerDetails.lastName,
      email: getState().customerDetails.email,
      mobile: getState().customerDetails.mobile,
      birthdate: Object.assign({}, getState().customerDetails.birthdate),
      billingAddress: getState().customerDetails.billingAddress,
      billingAddress2: getState().customerDetails.billingAddress2,
      city: getState().customerDetails.city,
      postcode: getState().customerDetails.postcode,
      county: getState().customerDetails.county,
      voucher: getState().bookingDetails.voucherCode,
      latitude: getState().customerDetails.latitude,
      longitude: getState().customerDetails.longitude,
    };
    data.birthdate.month++;
    if (getState().pickupDetails) {
      (data.pickupOrganization = getState().pickupDetails.organization),
        (data.pickupCountryCode = getState().pickupDetails.countryCode),
        (data.pickupProperty = getState().pickupDetails.property),
        (data.pickupPostcode = getState().pickupDetails.postcode),
        (data.pickupStreet = getState().pickupDetails.street),
        (data.pickupLocality = getState().pickupDetails.locality),
        (data.pickupTown = getState().pickupDetails.town),
        (data.pickupCounty = getState().pickupDetails.county),
        (data.pickupCode = getState().pickupDetails.code),
        (data.pickupDetails = getState().pickupDetails.details);
    }

    var endpoint = `${getState().apiBase || ''}/api/ui/v1/bookings`;
    var method = 'post';
    if (getState().bookingDetails.id) {
      endpoint = `${getState().apiBase || ''}/api/ui/v1/bookings/${getState().bookingDetails.id
        }`;
      method = 'put';
    }

    return Axios({
      method: method,
      url: endpoint,
      responseType: 'json',
      data: data,
    }).then(response => {
      dispatch(
        bookingDetails({
          bookingDetails: {
            id: response.data.id,
            voucherCode: response.data.voucherCode,
            bill: response.data.bill,
          },
        }),
      );
      return response.data;
    });
  };
};

export const pay = payload => {
  return function (dispatch, getState) {
    return Axios({
      method: 'post',
      url: `${getState().apiBase || ''}/api/ui/v1/bookings/${getState().bookingDetails.id
        }/payments/demo/charge`,
      responseType: 'json',
      data: {
        data: humps.decamelizeKeys(payload),
        reservation_handle: getState().reservationHandle,
        start_date: getState().bookingDetails.startDate,
        end_date: getState().bookingDetails.endDate,
      },
    }).then(response => {
      var details = { data: response.data };
      if (response.data && !response.data.requires_action) details['id'] = null;
      if (response.status === 200) {
        dispatch(bookingDetails({ bookingDetails: details }));
        if (response.data.status == 'succeeded') {
          if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                transaction_id: details.id,
                value: getState().bookingDetails.bill.totalWDeposit,
                currency: getState().programDefaults.currencyCode,
                items: [
                  {
                    item_name: getState().analytics.event_action,
                    index: 0,
                    price: getState().bookingDetails.bill.totalWDeposit,
                    quantity: 1
                  }
                ]
              }
            });
          }
          if (typeof window.fbq !== 'undefined') {
            fbq('track', 'Purchase', {
              currency: getState().programDefaults.currencyCode,
              value: getState().bookingDetails.bill.total
            });
          }
          if (typeof window.hj !== 'undefined') {
            hj('trigger', 'booking_completion');
          }
          dispatch(setReservationHandle(null));
        }
      }
      return response.data;
    });
  };
};

export const reserve = payload => {
  return function (dispatch, getState) {
    return Axios({
      method: 'post',
      url: `${getState().apiBase || ''}/api/ui/v1/program/reserve`,
      responseType: 'json',
      data: humps.decamelizeKeys(payload),
    }).then(response => {
      if (response.status === 200)
        dispatch(setReservationHandle(response.data.handle));
      return response.data;
    });
  };
};

export const unreserve = () => {
  return function (dispatch, getState) {
    if (getState().reservationHandle) {
      return Axios({
        method: 'post',
        url: `${getState().apiBase || ''}/api/ui/v1/program/unreserve`,
        responseType: 'json',
        data: {
          handle: getState().reservationHandle,
        },
      }).then(response => {
        if (response.status === 200) dispatch(setReservationHandle(null));
        return response.data;
      });
    }
  };
};
