import PropTypes from 'prop-types';
import React from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  FormattedDate,
} from 'react-intl';

import WrappedCardElement from './WrappedCardElement';

import {
  Elements,
  CardExpiryElement,
  CardNumberElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';

class CardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.fullNameRegexp = /(\w.+\s).+/i;

    this.zipRef = React.createRef();
    this.holderRef = React.createRef();
  }

  handleSubmit = () => {
    this.props.actions
      .book()
      .then(data => {
        this.validateCustomField(this.zipRef);
        this.validateCustomField(this.holderRef, this.fullNameRegexp);
        this.props.stripe
          .createPaymentMethod('card', {
            billing_details: {
              name: this.holderRef.current.value,
              email: this.props.customerDetails.email,
              address: {
                city: this.props.customerDetails.city,
                country: this.props.programDefaults.countryCode,
                line1: this.props.customerDetails.billingAddress,
                line2: this.props.customerDetails.billingAddress2,
                state: this.props.customerDetails.county,
                postal_code: this.zipRef.current.value,
              },
            },
          })
          .then(result => {
            if (result.error) {
              return this.props.paymentResult({ error: 'payment' });
            } else {
              this.props.actions
                .pay({
                  paymentMethodId: result.paymentMethod.id,
                })
                .then(result => {
                  this.handleResponse(result);
                })
                .catch(error => {
                  return this.props.paymentResult(error.response.data);
                });
            }
          });
      })
      .catch(error => {
        return this.props.paymentResult({ error: 'booking' });
      });
  };

  handleResponse = response => {
    if (response.requires_action) {
      this.handleAction(response);
    } else {
      return this.props.paymentResult(response);
    }
  };

  handleAction = response => {
    this.props.stripe
      .handleCardAction(response.payment_intent_client_secret)
      .then(result => {
        this.props.actions
          .pay({
            paymentIntentId: result.paymentIntent.id,
          })
          .then(result => {
            return this.props.paymentResult(result);
          })
          .catch(error => {
            return this.props.paymentResult({ error: 'payment' });
          });
      })
      .catch(error => {
        return this.props.paymentResult({ error: 'payment' });
      });
  };

  validateCustomField = (ref, regexp = null) => {
    if (ref.current.value.length == 0) {
      ref.current.classList.add(
        'StripeElement--empty',
        'StripeElement--invalid',
      );
      ref.current.parentElement.classList.add('CardElement--wrapper-error');

      throw 'Highlighted field cannot be empty.';
    } else if (regexp && !regexp.test(ref.current.value)) {
      ref.current.classList.add(
        'StripeElement--empty',
        'StripeElement--invalid',
      );
      ref.current.parentElement.classList.add('CardElement--wrapper-error');

      throw 'Entered value is invalid.';
    } else {
      ref.current.classList.remove(
        'StripeElement--empty',
        'StripeElement--invalid',
      );
      ref.current.parentElement.classList.remove('CardElement--wrapper-error');
    }
  };

  zipOrPostalCode = () => {
    if (this.props.programDefaults.countryCode === 'US') {
      return (
        <FormattedHTMLMessage id={`booking_wizard.sections.payment.zip_code`} />
      );
    } else {
      return (
        <FormattedHTMLMessage
          id={`booking_wizard.sections.payment.postal_code`}
        />
      );
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          <div className="col-6">
            <div className="StripeElement--header">Card Information</div>
          </div>
          <div className="col-6">
            <div className="StripeElement--card-logo"></div>
            <div className="StripeElement--card-logo"></div>
            <div className="StripeElement--card-logo"></div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <WrappedCardElement icon="fa-user" label="Cardholder Name (full)">
              {(onFocus, onBlur, onChange, focused) => (
                <input
                  type="text"
                  className="StripeElement StripeElement--holder"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  focused={focused.toString()}
                  ref={this.holderRef}
                />
              )}
            </WrappedCardElement>
          </div>
          <div className="col-md-6">
            <WrappedCardElement icon="fa-credit-card" label="Card Number">
              {(onFocus, onBlur, onChange, focused) => (
                <CardNumberElement
                  style={{ base: { fontSize: '16px' } }}
                  className="StripeElement--card"
                  placeholder=""
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  focused={focused}
                />
              )}
            </WrappedCardElement>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <WrappedCardElement icon="fa-calendar-alt" label="Expiry Date">
              {(onFocus, onBlur, onChange, focused) => (
                <CardExpiryElement
                  className="StripeElement--expiry"
                  placeholder={focused ? 'MM / YY' : ''}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  focused={focused}
                  style={{
                    base: {
                      fontSize: '16px',
                      '::placeholder': {
                        color: '#6c757d',
                        fontSize: '15px',
                        fontWeight: '300',
                      },
                    },
                  }}
                />
              )}
            </WrappedCardElement>
          </div>
          <div className="col-md-4">
            <WrappedCardElement icon="fa-lock-alt" label="CVC">
              {(onFocus, onBlur, onChange, focused) => (
                <CardCVCElement
                  style={{ base: { fontSize: '16px' } }}
                  className="StripeElement--cvc"
                  placeholder=""
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  focused={focused}
                />
              )}
            </WrappedCardElement>
          </div>
          <div className="col-md-4">
            <WrappedCardElement
              icon="fa-home-lg-alt"
              label={this.zipOrPostalCode()}
            >
              {(onFocus, onBlur, onChange, focused) => (
                <input
                  type="text"
                  className="StripeElement StripeElement--zip"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  focused={focused.toString()}
                  ref={this.zipRef}
                />
              )}
            </WrappedCardElement>
          </div>
        </div>
      </form>
    );
  }
}

export default injectStripe(CardForm, { withRef: true });

CardForm.propTypes = {
  actions: PropTypes.object,
  stripe: PropTypes.object,
  paymentResult: PropTypes.func,
  programDefaults: PropTypes.object,
  customerDetails: PropTypes.object,
};
