import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage, FormattedDate } from 'react-intl';

export default class WrappedCardElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      complete: false,
      error: false,
      empty: true,
    }

    this.wrapperRef = React.createRef();
  }

  onFocus = (e) => {
    this.setState({focused: true})
  }

  onBlur = (e) => {
    this.setState({focused: false})
  }

  onChange = (e) => {
    if (e.target) {
      if (e.target.value.length === 0) this.setState({empty: true})
      else this.setState({empty: false})
    } else {
      this.setState({empty: e.empty})
      if (e.error) this.setState({error: true})
      else this.setState({error: false})
    }


  }

  render() {
    var wrapperClass = classnames("CardElement--wrapper", {
      "CardElement--wrapper-focused": (this.state.focused),
      "CardElement--wrapper-empty": (this.state.empty),
      "CardElement--wrapper-error": (this.state.error),
    })
    return (
      <div className={wrapperClass} ref={this.wrapperRef}>
        {this.props.children(this.onFocus, this.onBlur, this.onChange, this.state.focused)}
        <label className="StripeElement CardElement__label">
          <i className={`fal ${this.props.icon}`} aria-hidden="true" />
          <div>{this.props.label}</div>
        </label>
      </div>
    )
  }
}
