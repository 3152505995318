/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import setupCSRFToken from '../setupCSRFToken';
import ReactOnRails from 'react-on-rails';

import { Application as Stimulus } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import EnvioApp from '../bundles/Envio/startup/EnvioApp';

import store from '../bundles/Envio/store/store';

ReactOnRails.register({
  EnvioApp,
});

ReactOnRails.registerStore({
  store,
});

const stim = Stimulus.start();
const context = require.context('../bundles/Envio/controllers/landing', true, /\.js$/);
stim.load(definitionsFromContext(context));
window.stimulus = stim;

setupCSRFToken();
