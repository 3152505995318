import { connect } from 'react-redux';
import BookingWizardModal from '../components/BookingWizardModal';
import * as actions from '../actions/envioActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => {
  state.currentVariant = () => {
    return state.variants[state.currentVariantIndex];
  }
  return state;
};
const mapDispatchToProps = (dispatch, ownProps) => {
  var props = {actions: {}};

  props.actions.fetchDefaults = () => {
    dispatch(actions.fetchDefaults());
  };
  props.actions.bookingWizardLanuch = (showHelp) => {
    dispatch(actions.bookingWizardLaunch(showHelp));
  };
  props.actions.closeBookingWizardModal = (payload) => {
    dispatch(actions.bookingWizardClose(payload));
    dispatch(actions.unreserve());
  };
  props.actions.updateCustomerDetails = (payload) => {
    return dispatch(actions.updateCustomerDetails(payload));
  }
  props.actions.updateBookingDetails = (payload) => {
    dispatch(actions.bookingDetails(payload));
  };
  props.actions.updatePickupDetails = (payload) => {
    dispatch(actions.pickupDetails(payload));
  };
  props.actions.setValidationStatus = (section, status) => {
    dispatch(actions.setValidationStatus(section, status));
  };
  props.actions.tryVoucherCode = (code) => {
    return dispatch(actions.tryVoucherCode(code));
  };
  props.actions.book = () => {
    return dispatch(actions.book());
  };
  props.actions.pay = (payload) => {
    return dispatch(actions.pay(payload));
  };
  props.actions.reserve = (payload) => {
    return dispatch(actions.reserve(payload));
  };
  props.actions.unreserve = () => {
    return dispatch(actions.unreserve());
  };
  props.actions.switchVariant = (index) => {
    dispatch(actions.switchVariant(index));
  };

  return props;
};

// Don't forget to actually use connect!
// Note that we don't export Envio, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(BookingWizardModal);
