import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { defaultMessages } from '../translations/default';

export default class ConfirmationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={this.props.show ? { display:  'block', height: '100%' } : { display: 'none' }}>
        <div className="content__header-image content__header-image--confirmation"></div>
        <div className="container-fluid container__content--confirmation">
          <div style={{ display: this.props.show ? 'block' : 'none' }}>
            <div className="content__tick">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 113.671 113.517"
              >
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n      .cls-1, .cls-2 {\n        fill: none;\n        stroke: #f5e00a;\n        }\n\n      .cls-2 {\n           }\n    ',
                    }}
                  />
                </defs>
                <g
                  id="Group_79"
                  data-name="Group 79"
                  transform="translate(0 134)"
                >
                  <g
                    id="Group_77"
                    data-name="Group 77"
                    transform="translate(2 -132)"
                  >
                    <path
                      id="Path_40"
                      data-name="Path 40"
                      className="cls-1"
                      d="M95.713,18.408A54.564,54.564,0,1,0,104.847,32.6"
                    />
                  </g>
                  <g
                    id="Group_78"
                    data-name="Group 78"
                    transform="translate(30.643 -115.151)"
                  >
                    <path
                      id="Path_41"
                      data-name="Path 41"
                      className="cls-2"
                      d="M0,29.857,25.585,55.443,81.028,0"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h1>
              <FormattedMessage id="booking_wizard.confirmation.thanks" />
            </h1>
            <p>
              <FormattedMessage id="booking_wizard.confirmation.confirmation_email" />
            </p>
          </div>
        </div>
        <div className="container__buttons">
          <div className="row row__buttons">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary content__btn-help--close"
                onClick={this.props.onClose}
              >
                <FormattedMessage id="booking_wizard.confirmation.close" />
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid container__footer">
          <div className="row footer__row footer__row--powered">
            <div className="col">
              <span>
                <FormattedMessage id="booking_wizard.footer.powered" />
              </span>
              &nbsp;
              <span>
                <a
                  href="http://envio.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="booking_wizard.footer.company_link" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
