import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import classnames from 'classnames'

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  get currentStepName() {
    return this.currentStepData.name;
  }

  get currentStepData() {
    return this.props.steps[this.props.currentStepIndex];
  }

  steps = () => {
    return this.props.steps.map((step, index) =>
      <li key={index} className={classnames('steps__step', {'steps__step--ticked': (index < this.props.currentStepIndex), 'steps__step--active': (index == this.props.currentStepIndex)})}>
        <FormattedMessage id={`booking_wizard.steps.${step.name}`} />
      </li>
    );
  }

  render() {
    return(
      <div className={classnames({"d-md-none": this.currentStepData.hideOnDesktop})} style={{ height: '22%' }}>
        <div className="container-fluid progress-bar__container">
          <div className="row">
            <div className={classnames("content__progress-bar", {"content__progress-bar--three-steps": this.props.steps.length==3})}>
              <ul className="progress-bar__steps">
                {this.steps()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
