import { Controller } from 'stimulus';
import moment from 'moment';
import Axios from 'axios';

export default class extends Controller {
  static targets = [
    'container',
    'containerSuccess',
    'demoCard',
    'newCard',
    'demoPrice',
    'newPrice',
    'chargePrice',
    'countdown',
    'countdownWrapper',
    'btn',
    'btnConfirm',
    'spinner',
    'chargeWrapper',
  ];
  static countdownInterval = null;
  static totalSeconds = 0;
  static price = 0;
  static kind = '';

  connect() {
    this.checkUrlParams();
    this.calculateRemaining();
    this.totalSeconds = this.returnDate.diff(moment(), 'seconds');
    this.countdownInterval = setInterval(() => {
      if (this.hasDemoCardTarget) {
        this.count();
        this.countdownTarget.textContent = `${this.daysLeft}days ${this.hoursLeft}hrs ${this.minLeft}mins`;
      }
    }, 1000);
    this.btnTarget.disabled = true;
    this.handleDefault();

    this.initStripe();
  }

  get afterDemo() {
    return JSON.parse(this.data.get('demo'));
  }

  get period() {
    return this.data.get('period');
  }

  get key() {
    return this.data.get('key');
  }

  get currency() {
    return this.data.get('currency');
  }

  get id() {
    return this.data.get('id');
  }

  get email() {
    return this.data.get('email');
  }

  get option() {
    return this.data.get('option');
  }

  initStripe() {
    if (window.Stripe) {
      this.stripe = window.Stripe(this.key);
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.stripe = window.Stripe(this.key);
      });
    }
  }

  checkUrlParams() {
    var success = location.search.split('success=')[1];

    if (success === undefined) {
      return;
    } else if (success === 'true') {
      this.success();
    } else if (success === 'false') {
      this.btnTarget.classList.add('btn-danger');
      this.btnTarget.innerText = 'declined, please try again';
    }
  }

  count() {
    if (this.totalSeconds > 0) {
      --this.secLeft;

      if (this.minLeft > 0 && this.secLeft <= 0) {
        this.secLeft = 59;
        --this.minLeft;
      }

      if (this.hoursLeft > 0 && this.minLeft <= 0) {
        this.minLeft = 59;
        --this.hoursLeft;
      }

      if (this.daysLeft > 0 && this.hoursLeft <= 0) {
        this.hoursLeft = 24;
        --this.daysLeft;
      }

      --this.totalSeconds;
    } else {
      this.countdownWrapperTarget.style.display = 'none';

      clearInterval(this.countdownInterval);
    }
  }

  calculateRemaining() {
    this.returnDate = moment(this.period).subtract(7, 'h');

    this.secLeft = this.returnDate.diff(moment(), 'seconds');
    this.daysLeft = Math.floor(this.secLeft / (3600 * 24));
    this.secLeft -= this.daysLeft * 3600 * 24;
    this.hoursLeft = Math.floor(this.secLeft / 3600);
    this.secLeft -= this.hoursLeft * 3600;
    this.minLeft = Math.floor(this.secLeft / 60);
    this.secLeft -= this.minLeft * 60;
  }

  confirm() {
    this.btnTarget.style.display = 'none';
    this.btnConfirmTarget.style.display = 'block';
  }

  perform() {
    this.spinnerTarget.style.display = 'inline-block';

    Axios({
      method: 'post',
      url: `/api/ui/v1/bookings/${this.id}/payments/purchase/instant_charge`,
      responseType: 'json',
      data: {
        kind: this.kind,
      },
    })
      .then(response => {
        this.success();
      })
      .catch(error => {
        console.error(error);
        this.fallbackPurchase();
      });
  }

  fallbackPurchase() {
    Axios({
      method: 'post',
      url: `/api/ui/v1/bookings/${this.id}/payments/purchase/charge`,
      responseType: 'json',
      data: {
        source: '',
        kind: this.kind,
      },
    })
      .then(response => {
        this.checkout(response.data.session_id);
      })
      .catch(error => {
        console.error(error);
        this.error();
      });
  }

  error() {
    this.btnConfirmTarget.classList.add('btn-danger');
    this.btnConfirmTarget.getElementsByTagName('span')[0].innerText =
      'declined, please try again';
    this.spinnerTarget.style.display = 'none';
  }

  checkout(sessionId) {
    this.stripe.redirectToCheckout({
      sessionId: sessionId,
    });
  }

  success() {
    this.containerTarget.style.display = 'none';
    this.containerSuccessTarget.style.display = 'block';
  }

  chooseCard = e => {
    if (
      this.hasDemoCardTarget &&
      (e.currentTarget === this.demoCardTarget || e === this.demoCardTarget)
    ) {
      this.demoCardTarget.classList.add('purchase-page__card--highlighted');
      if (this.hasNewCardTarget) {
        this.newCardTarget.classList.remove('purchase-page__card--highlighted');
      }
      this.changeCharge(
        this.demoPriceTarget.innerText,
        this.demoCardTarget.dataset['price'],
      );
      this.kind = 'demo_unit_keep_price';
    } else {
      this.newCardTarget.classList.add('purchase-page__card--highlighted');
      if (this.hasDemoCardTarget) {
        this.demoCardTarget.classList.remove(
          'purchase-page__card--highlighted',
        );
      }
      this.changeCharge(
        this.newPriceTarget.innerText,
        this.newCardTarget.dataset['price'],
      );
      this.kind = 'new_unit_purchase_price';
    }
    this.btnTarget.disabled = false;
  };

  handleDefault() {
    if (this.hasDemoCardTarget) {
      this.chooseCard(this.demoCardTarget);
    } else {
      this.chooseCard(this.newCardTarget);
    }
  }

  changeCharge(price, priceInt) {
    this.displayCharge(priceInt);
    this.chargePriceTarget.innerText = price;
    this.price = parseInt(priceInt);
  }

  displayCharge(price) {
    if (this.hasChargeWrapperTarget && price == 0) {
      this.chargeWrapperTarget.style.display = 'none';
    } else {
      this.chargeWrapperTarget.style.display = 'block';
    }
  }
}
