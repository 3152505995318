import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import NextButton from '../NextButton';

export default class DeliveryStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.mapRef = React.createRef();
  }

  get loc() {
    if (
      this.props.customerDetails &&
      this.props.customerDetails.latitude &&
      this.props.customerDetails.longitude
    ) {
      return {
        lat: this.props.customerDetails.latitude,
        lng: this.props.customerDetails.longitude,
      };
    } else {
      return null;
    }
  }

  get defaultLoc() {
    return {
      lat: this.props.programDefaults.mapInitialCoordinates[0],
      lng: this.props.programDefaults.mapInitialCoordinates[1],
    };
  }

  componentDidMount() {
    this.setupMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.customerDetails != prevProps.customerDetails ||
      this.props.ship2shopLocations != prevProps.ship2shopLocations
    ) {
      this.setupMap();
    }
  }

  setupMap = () => {
    if (this.loc) {
      if (this.mapRef && this.mapRef.current && !this.map) {
        this.map = new google.maps.Map(this.mapRef.current, {
          zoom: 10,
          center: this.defaultLoc,
          mapTypeControl: false,
        });
        this.marker = new google.maps.Marker({
          position: this.defaultLoc,
          map: this.map,
          icon:
            'https://mt.google.com/vt/icon/name=icons/spotlight/home_L_8x.png&scale=1',
          visible: false,
        });
        this.markers = [];
      }
    } else {
      this.map = null;
      this.marker = null;
    }
    if (this.map) {
      if (this.loc) {
        this.map.setCenter(this.loc);
        this.map.setZoom(15);
        this.marker.setPosition(this.loc);
        this.marker.setVisible(true);
        this.marker.setMap(this.map);
      } else {
        this.map.setCenter(this.defaultLoc);
        this.map.setZoom(10);
        this.marker.setMap(null);
      }

      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
      if (
        this.props.ship2shopLocations &&
        this.props.ship2shopLocations.length > 0
      ) {
        this.bounds = new google.maps.LatLngBounds();
        this.markers = this.props.ship2shopLocations.map(shop => {
          var marker = shop.toMapMarker(this.map, infoWindow => {
            infoWindow.open(this.map, marker);
            if (this.lastOpenedWindow !== undefined)
              this.lastOpenedWindow.close();
            this.lastOpenedWindow = infoWindow;

            var data = {
              organization: shop.address.name,
              countryCode: shop.address.country,
              property: shop.address.property,
              postcode: shop.address.postcode,
              street: shop.address.street,
              locality: shop.address.locality,
              town: shop.address.city,
              county: shop.address.county,
              code: shop.addressCode,
              details: {
                availability: shop.availability,
                availabilityExceptions: shop.availabilityExceptions,
              },
              shop: shop,
            };

            this.props.actions.setValidationStatus('shipping', true);
            this.props.actions.updatePickupDetails({ pickupDetails: data });
          });
          this.bounds.extend(marker.getPosition());
          return marker;
        });
        this.map.fitBounds(this.bounds);
      }
    }
  };

  details = () => {
    if (this.props.pickupMode) {
      if (this.props.pickupMode.ship2shop) {
        if (this.props.pickupDetails) {
          return (
            <div className="content__pickup">
              <div
                className="pickup__description"
                dangerouslySetInnerHTML={{
                  __html: this.props.pickupDetails.shop.pickupDescription(),
                }}
              ></div>
              <table
                className="pickup__text"
                cellSpacing="0"
                dangerouslySetInnerHTML={{
                  __html: this.props.pickupDetails.shop.renderAvailability(),
                }}
              ></table>
            </div>
          );
        } else {
          return (
            <div className="content__pickup">
              <h4>
                <FormattedMessage
                  id={'booking_wizard.sections.delivery.ship2shop.header'}
                />
              </h4>
              <p>
                <FormattedMessage
                  id={'booking_wizard.sections.delivery.ship2shop.no_choice'}
                />
              </p>
            </div>
          );
        }
      } else {
        return (
          <div className="content__pickup">
            <h4>
              <FormattedMessage
                id={'booking_wizard.sections.delivery.home_delivery.header'}
              />
            </h4>
            <p className="pickup__text">
              <FormattedMessage
                id={'booking_wizard.sections.delivery.home_delivery.info'}
              />
            </p>
            <br />
            <h4>
              <FormattedMessage
                id={'booking_wizard.sections.delivery.home_delivery.check'}
              />
            </h4>
            <div className="pickup__text">
              {this.props.customerDetails.firstName}{' '}
              {this.props.customerDetails.lastName}
              <br />
              {this.props.customerDetails.billingAddress}
              <br />
              {this.props.customerDetails.billingAddress2 && (
                <span>
                  {this.props.customerDetails.billingAddress2}
                  <br />
                </span>
              )}
              {this.props.customerDetails.city}{' '}
              {this.props.customerDetails.postcode}
            </div>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <div>
        <div className="container-fluid container__content container__content--delivery">
          <div className="row">
            {this.loc && (
              <div className="col-md-6 col-md--map order-2 order-md-1">
                <div className="map-wrapper">
                  <div className="map-container" ref={this.mapRef}></div>
                </div>
              </div>
            )}
            <div className="col-md-6 col-md--details order-md-2">
              {this.details()}
              <div className="container__buttons container__buttons--delivery-step">
                <div className="row row__buttons">
                  <div className="col col--left">
                    <button
                      className="btn btn-default--modal-light"
                      type="button"
                      onClick={this.props.onPrev}
                    >
                      <FormattedMessage id={`booking_wizard.navigation.back`} />
                    </button>
                  </div>
                  <div className="col col--right">
                    <NextButton
                      onNext={this.props.onNext}
                      stepName="shipping"
                      {...this.props}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
