import { Controller } from 'stimulus';
import Axios from 'axios';

export default class extends Controller {
  static targets = [];

  get newFlow() {
    return this.data.get('newFlow') !== null;
  }

  get showHelp() {
    return this.data.get('showHelp') !== null;
  }

  trigger(e) {
    if (this.newFlow) {
      e.preventDefault();
      e.stopPropagation();
      ReactOnRails.getStore('store').dispatch({
        type: 'ENVIO_BOOKING_WIZARD_LAUNCH',
        showHelp: this.showHelp
      });
      return false;
    } else {
      return true;
    }
  }
}
