import PropTypes from 'prop-types';
import React from 'react';
import Steps from './BookingWizard/Steps';
import ProgressBar from './BookingWizard/ProgressBar';

import { FormattedMessage } from 'react-intl';
import { defaultMessages } from '../translations/default';

// Cards:
import HelpCard from './HelpCard';
import TermsCard from './TermsCard';
import ConfirmationCard from './ConfirmationCard';

// Steps:
import DatepickerStep from './BookingWizard/Steps/DatepickerStep';
import CustomerDetailsStep from './BookingWizard/Steps/CustomerDetailsStep';
import DeliveryStep from './BookingWizard/Steps/DeliveryStep';
import SummaryStep from './BookingWizard/Steps/SummaryStep';
import PaymentStep from './BookingWizard/Steps/PaymentStep';

export default class BookingWizard extends React.Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    var steps = [];
    steps.push({ name: 'datepicker' });
    steps.push({ name: 'customer_details' });
    if (
      this.props.programDefaults.countryCode == 'GB' &&
      this.props.programDefaults.shipmentProvider == 'dpd'
    ) {
      steps.push({ name: 'delivery', hideOnDesktop: true });
    }
    steps.push({ name: 'summary' });
    steps.push({ name: 'payment' });

    this.state = {
      step: {
        index: 0,
      },
      steps: steps,
      showHelp: this.props.bookingWizardModal.showHelp,
      showTerms: false,
      showConfirmation: false,
    };
  }

  componentDidMount() {
    this.trackFunnel();
  }

  get currentStepIndex() {
    return this.state.step.index;
  }

  get nextStepIndex() {
    return (this.currentStepIndex + 1) % this.stepsCount;
  }

  get prevStepIndex() {
    return (this.currentStepIndex - 1) % this.stepsCount;
  }

  get stepsCount() {
    return this.state.steps.length;
  }

  get initialHelpOpen() {
    return (
      this.props.bookingWizardModal && this.props.bookingWizardModal.showHelp
    );
  }

  trackFunnel = () => {
    if (window.hj) {
      if (this.state.showConfirmation) {
        var pagename = 'confirmation';
      } else {
        var pagename = this.state.steps[this.state.step.index].name;
      }
      window.hj('vpv', '/' + pagename);
    }
  };

  next = () => {
    this.setState(
      prevState => ({
        step: { ...prevState.step, index: this.nextStepIndex },
      }),
      this.trackFunnel(),
    );
    this.rootRef.current.parentNode.scrollTop = 0;
  };

  prev = () => {
    this.setState(
      prevState => ({
        step: { ...prevState.step, index: this.prevStepIndex },
      }),
      this.trackFunnel(),
    );
    this.rootRef.current.parentNode.scrollTop = 0;
  };

  toggleHelp = () => {
    this.setState(prevState => ({
      showHelp: !prevState.showHelp,
    }));
    if (this.state.showHelp == false) {
      // clear 'showHelp' redux property
      this.props.actions.bookingWizardLanuch(false);
    }
  };

  toggleTerms = () => {
    this.setState(prevState => ({
      showTerms: !prevState.showTerms,
    }));
  };

  toggleConfirmation = () => {
    this.setState(
      prevState => ({
        showConfirmation: !prevState.showConfirmation,
      }),
      this.trackFunnel(),
    );
  };

  faqLink = () => {
    if (this.props.programDefaults.faqUrl) {
      return (
        <a
          className="btn btn-link footer__help-btn"
          target="_blank"
          rel="noopener noreferrer"
          href={this.props.programDefaults.faqUrl}
        >
          <FormattedMessage id="booking_wizard.footer.click_here" />
        </a>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-link footer__help-btn"
          onClick={this.toggleHelp}
        >
          <FormattedMessage id="booking_wizard.footer.click_here" />
        </button>
      );
    }
  };

  render() {
    return (
      <div
        ref={this.rootRef}
        className={`${
          this.props.variants.length > 1
            ? 'modal-container--tall'
            : 'modal-container--small'
        }`}
      >
        <HelpCard
          toggleHelp={this.toggleHelp}
          onClose={
            this.initialHelpOpen ? this.props.onModalClose : this.toggleHelp
          }
          show={this.state.showHelp}
          initialOpen={this.initialHelpOpen}
          content={this.props.programDefaults.faq}
          brand={this.props.brand}
        />
        <TermsCard
          toggleTerms={this.toggleTerms}
          onClose={this.toggleTerms}
          show={this.state.showTerms}
          content={this.props.programDefaults.terms}
        />
        <ConfirmationCard
          onClose={this.props.onModalClose}
          show={this.state.showConfirmation && !this.state.showHelp}
          toggleHelp={this.toggleHelp}
        />
        <div
          style={
            this.state.showHelp ||
            this.state.showTerms ||
            this.state.showConfirmation
              ? { display: 'none' }
              : { display: 'block', height: '100%' }
          }
        >
          <Steps currentStepIndex={this.currentStepIndex} useTransition={true}>
            <div className="content__header-image content__header-image--step1">
              <div
                className="content__close-btn"
                onClick={this.props.onModalClose}
              ></div>
            </div>
            <div className="content__header-image content__header-image--step2">
              <div
                className="content__close-btn"
                onClick={this.props.onModalClose}
              ></div>
            </div>
            {this.props.programDefaults.countryCode == 'GB' &&
              this.props.programDefaults.shipmentProvider == 'dpd' && (
                <div className="content__header-image content__header-image--step3">
                  <div
                    className="content__close-btn"
                    onClick={this.props.onModalClose}
                  ></div>
                </div>
              )}
            <div className="content__header-image content__header-image--step4">
              <div
                className="content__close-btn"
                onClick={this.props.onModalClose}
              ></div>
            </div>
            <div className="content__header-image content__header-image--step5">
              <div
                className="content__close-btn"
                onClick={this.props.onModalClose}
              ></div>
            </div>
          </Steps>
          <ProgressBar
            steps={this.state.steps}
            currentStepIndex={this.currentStepIndex}
          />
          <Steps currentStepIndex={this.currentStepIndex} {...this.props}>
            <DatepickerStep
              onNext={this.next}
              onCancel={this.props.onModalClose}
              {...this.props}
            />
            <CustomerDetailsStep
              onNext={this.next}
              onPrev={this.prev}
              {...this.props}
            />
            {this.props.programDefaults.countryCode == 'GB' &&
              this.props.programDefaults.shipmentProvider == 'dpd' && (
                <DeliveryStep
                  onNext={this.next}
                  onPrev={this.prev}
                  {...this.props}
                />
              )}
            <SummaryStep
              onNext={this.next}
              onPrev={this.prev}
              {...this.props}
            />
            <PaymentStep
              currentStepIndex={this.currentStepIndex}
              onSuccess={this.toggleConfirmation}
              onPrev={this.prev}
              {...this.props}
              onToggleTerms={this.toggleTerms}
            />
          </Steps>
          <div className="container-fluid container__footer container__footer container__footer--booking">
            <div className="row footer__row">
              <div className="col">
                <span>
                  <FormattedMessage id="booking_wizard.footer.need_help" />
                  &nbsp;
                  {this.faqLink()}
                </span>
              </div>
            </div>
            <div className="row footer__row footer__row--powered">
              <div className="col">
                &nbsp;
                <span>
                  <FormattedMessage id="booking_wizard.footer.powered" />
                </span>
                &nbsp;
                <span>
                  <a
                    href="http://envio.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="booking_wizard.footer.company_link" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
