import PropTypes from 'prop-types';
import React from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  FormattedDate,
} from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import NextButton from '../NextButton';

export default class SummaryStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    moment.locale(this.props.programDefaults.i18n.locale);

    this.voucherInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.actions.setValidationStatus('summary', true);
  }

  get bill() {
    return this.props.bookingDetails.bill || this.props.programDefaults.bill;
  }

  get voucherValid() {
    return this.props.bookingDetails.voucherCode && this.bill.voucherItem;
  }

  get voucherInvalid() {
    return this.props.bookingDetails.voucherCode && !this.bill.voucherItem;
  }

  tryVoucher = () => {
    this.props.actions.tryVoucherCode(this.voucherInputRef.current.value);
  };

  get startDateMoment() {
    return moment(this.props.bookingDetails.startDate);
  }

  get endDateMoment() {
    return moment(this.props.bookingDetails.endDate);
  }

  get startDateText() {
    return this.startDateMoment.format(
      this.props.programDefaults.i18n.formatShort,
    );
  }

  get endDateText() {
    return this.endDateMoment.format(
      this.props.programDefaults.i18n.formatShort,
    );
  }

  render() {
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        <div
          className="container-fluid container__content container__content--summary"
        >
          <div className="summary__table">
            <p className="summary__date-scope">
              <i className="fa fa-calendar-o"></i>&nbsp;{this.startDateText}{' '}
              <i className="fa fa-long-arrow-right"></i>&nbsp;{this.endDateText}
            </p>
            {this.bill.demoItem && (
              <div>
                <FormattedMessage
                  id={'booking_wizard.sections.payment.items.demo'}
                  values={{
                    mode:
                      this.props.programDefaults.mode.charAt(0).toUpperCase() +
                      this.props.programDefaults.mode.slice(1),
                  }}
                />
                <div className="summary__value">
                  {this.bill.demoItem.amountFormatted}
                </div>
              </div>
            )}
            {this.bill.handlingItem && (
              <div className="summary__shipping">
                <FormattedMessage
                  id={'booking_wizard.sections.payment.items.shipping'}
                />
                <div className="summary__value">
                  {this.bill.handlingItem.amountFormatted}
                </div>
              </div>
            )}
            {this.bill.depositItem && (
              <div>
                <FormattedMessage
                  id={'booking_wizard.sections.payment.items.deposit'}
                />
                <div className="summary__value">
                  {this.bill.depositItem.amountFormatted}
                </div>
              </div>
            )}
            {this.bill.voucherItem && (
              <div>
                <FormattedMessage
                  id={'booking_wizard.sections.payment.items.discount'}
                />
                <div className="summary__value">
                  {this.bill.voucherItem.amountFormatted}
                </div>
              </div>
            )}
            <div className="summary__total">
              <FormattedMessage
                id={'booking_wizard.sections.payment.items.total'}
                values={{ currency: this.props.programDefaults.currencyCode }}
              />
              <div className="summary__value">{this.bill.totalFormatted}</div>
            </div>
          </div>
          <div className="summary__envioPlus">
            <a
              href="https://www.envio.rentals/membership"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="envioPlus__icon">
                <strong>
                  {this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.payment.envio_plus.envio',
                  })}
                </strong>
                <span>
                  {this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.payment.envio_plus.plus',
                  })}
                </span>
              </div>
              <div className="envioPlus__text">
                <strong>
                  {this.props.intl.formatMessage({
                    id: 'booking_wizard.sections.payment.envio_plus.offer',
                  })}
                </strong>
                <div className="text__findOutDesktop">
                  {this.props.intl.formatMessage({
                    id:
                      'booking_wizard.sections.payment.envio_plus.find_out_more_desktop',
                  })}
                </div>
              </div>
            </a>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-4 col-sm-12">
              <div className="summary__voucher">
                <div className="input-group">
                  <input
                    className={classnames('form-control', {
                      'is-valid': this.voucherValid,
                      'is-invalid': this.voucherInvalid,
                    })}
                    placeholder={this.props.intl.formatMessage({
                      id: 'booking_wizard.sections.payment.voucher',
                    })}
                    type="text"
                    ref={this.voucherInputRef}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary btn-primary--apply"
                      type="button"
                      onClick={this.tryVoucher}
                    >
                      <FormattedMessage
                        id={'booking_wizard.sections.payment.apply'}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container__buttons">
          <div className="row row__buttons">
            <div className="col">
              <button
                className="btn btn-default--modal-light"
                type="button"
                onClick={this.props.onPrev}
              >
                <FormattedMessage id={`booking_wizard.navigation.back`} />
              </button>
            </div>
            <div className="col">
              <NextButton
                onNext={this.props.onNext}
                stepName="summary"
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
