import Axios from 'axios';

export default function () {
  const csrfTag = document.querySelector('meta[name=csrf-token]');

  if (csrfTag !== null) {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  }
}
