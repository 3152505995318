import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { defaultMessages } from '../translations/default';

export default class TermsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  generateTerms = () => {
    return this.props.content.items.map((item, key) => (
      <p key={key} className="content-qa__answer">
        {item}
      </p>
    ));
  };

  render() {
    return (
      <div style={this.props.show ? { display:  'block', height: '100%' } : { display: 'none' }}>
        <div className="content__header-image--help content__header-image">
          <span className="header-image__text">Terms & Conditions</span>
        </div>
        <div className="container-fluid container__content--faq">
          <div className="container-fluid container__content-qa">
            {this.generateTerms()}
          </div>
        </div>
        <div className="container__buttons">
          <div className="row row__buttons">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary content__btn-help--back"
                onClick={this.props.toggleTerms}
              >
                <FormattedMessage id="booking_wizard.help.back" />
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid container__footer">
          <div className="row footer__row footer__row--powered">
            <div className="col">
              &nbsp;
              <span>
                <FormattedMessage id="booking_wizard.footer.powered" />
              </span>
              &nbsp;
              <span>
                <a
                  href="http://envio.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="booking_wizard.footer.company_link" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
